import React, { useCallback } from 'react'
import { Auth } from 'aws-amplify';
import { httpPost, httpDelete, httpPatch, httpGet, httpPut, httpDownload, httpCustomPatch, uploadFileToS3, httpCustomPost } from '../http'
import { CUSTOMERS_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from './ethos-notification-context';
import moment from 'moment';

const CustomerContext = React.createContext()

function CustomerProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getCustomers = useCallback(async (search = null, filter = null, user = null, limit = 12, offset = 0,sort=null) => {
		const normalizeValue = (value) => {
			if (!value) return undefined;
			if (typeof value === "string" && value.toLowerCase() === 'null') return undefined;
			return value;
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}`, jwtToken, {
				filter: normalizeValue(filter),
				search: normalizeValue(search),
				limit: limit,
				offset: offset,
				user: normalizeValue(user),
				sort:sort
			});

			var customersTmp = result.data.data;
            var customersTmp = result.data.data;
            if (filter === "ATTESA-PAGAMENTO") {
                var todayDate = moment().startOf('day');
                customersTmp.forEach(customer => {
                    if (customer.nextPaymentDate) {
                        var newPaymentMoment = moment(customer.nextPaymentDate).startOf('day');
                        var diff = newPaymentMoment.diff(todayDate, 'days');
                        customer.late_payment = diff < 0;
                    } else {
                        customer.late_payment = false;
                    }
                });
            }

			return {
				data: customersTmp,
				length: result.data.length,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const assignToOwner = useCallback(async (id, userId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${CUSTOMERS_ENDPOINT}/${id}/assign/resp`, jwtToken, {}, userId || '');
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const assignToSales = useCallback(async (id, userId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${CUSTOMERS_ENDPOINT}/${id}/assign/sales`, jwtToken, {}, userId || '');
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const assignToBroker = useCallback(async (id, userId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${CUSTOMERS_ENDPOINT}/${id}/assign/broker`, jwtToken, {}, userId || '');
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const listPracticesByCustomer = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/${id}/practices`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getSingleCustomer = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const newCustomer = useCallback(async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${CUSTOMERS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const editCustomer = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${CUSTOMERS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const changeCustomerStatus = useCallback(async (id, newState, data) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${CUSTOMERS_ENDPOINT}/${id}/next/${newState}`, jwtToken, data);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteCustomer = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${CUSTOMERS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const uploadFileCustomer = useCallback(async (id, type, file, filename, expirationDate) => {
		if (!file) return;
		var re = /(?:\.([^.]+))?$/;
		const fileExtension = re.exec(file.name)[1];
		// const fileBase64 = await getBase64(file);
		const body = {
			ext: fileExtension,
			filename: filename,
			expirationDate: expirationDate
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const response = await httpPost(`${CUSTOMERS_ENDPOINT}/${id}/upload/url/${type}`, jwtToken, body);

			await uploadFileToS3(response.data.url, file);

			body.fileId = response.data.fileId;
			const docResponse = await httpPut(`${CUSTOMERS_ENDPOINT}/${id}/upload/url/${type}`, jwtToken, body);

			return {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const downloadFileCustomer = useCallback(async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${CUSTOMERS_ENDPOINT}/download/url/${type}/${id}`, jwtToken);

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteFileCustomer = useCallback(async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${CUSTOMERS_ENDPOINT}/delete/${type}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getCustomersForPractice = useCallback(async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}?filter=CUSTOMERS`, jwtToken, {});

			obj = {
				data: result.data.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getCustomerActivities = useCallback(async (customerId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/${customerId}/activities`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getCustomerMemos = useCallback(async (customerId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/${customerId}/memos`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);


	const uploadProspects = useCallback(async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${CUSTOMERS_ENDPOINT}/from/csv`, jwtToken, formData);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: err.response.data,
				error: err,
				meta: null,
				status: err.response.status,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getCustomerFiles = useCallback(async (customerId, filter) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			var params = {};
			if (!!filter) {
				params.filter = filter;
			}
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/${customerId}/files`, jwtToken, params);

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const addCustomerStrike = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${CUSTOMERS_ENDPOINT}/${id}/strike`, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const addRelative = useCallback(async(id,relation,relativeId)=>{
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${CUSTOMERS_ENDPOINT}/${id}/add/relation/${relation}/${relativeId}`, jwtToken, {},"");
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (error) {
				return {
				data: null,
				error: error,
				meta: null,
				status: null,
			};
		}finally{
			unregisterPendingRequest();
		}
	},[registerPendingRequest, unregisterPendingRequest])

	const removeRelative = useCallback(async(id,relation,relativeId)=>{
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${CUSTOMERS_ENDPOINT}/${id}/remove/relation/${relation}/${relativeId}`, jwtToken, {},"");
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (error) {
				return {
				data: null,
				error: error,
				meta: null,
				status: null,
			};
		}finally{
			unregisterPendingRequest();
		}
	},[registerPendingRequest, unregisterPendingRequest])

	const getFilteredCustomers = useCallback(async (search = null, filterIds=null, limit = 12, offset = 0) => {
		const normalizeValue = (value) => {
			if (!value) return undefined;
			if (typeof value === "string" && value.toLowerCase() === 'null') return undefined;
			return value;
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPost(`${CUSTOMERS_ENDPOINT}/filtered`, jwtToken, {notCustomerIds:filterIds},{
				search: normalizeValue(search),
				limit: limit,
				offset: offset,
			});

			var customersTmp = result.data.data;

			return {
				data: customersTmp,
				length: result.data.length,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const generateCSVFromTable = useCallback(
    async (search = null, filter = null, user = null, limit = 12, offset = 0, sort = null) => {
      const normalizeValue = (value) => {
        if (!value) return undefined;
        if (typeof value === "string" && value.toLowerCase() === "null") return undefined;
        return value;
      };

      try {
        registerPendingRequest();
        const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        const result = await httpGet(`${CUSTOMERS_ENDPOINT}/csv`, jwtToken, {
          filter: normalizeValue(filter),
          search: normalizeValue(search),
          limit: limit,
          offset: offset,
          user: normalizeValue(user),
          sort: sort,
        });

        var customersTmp = result.data;
        if (filter === "ATTESA-PAGAMENTO") {
          for (var i = 0; i < customersTmp.length; i++) {
            if (!customersTmp[i].serviceCustomerData || !customersTmp[i].serviceCustomerData.length) {
              continue;
            }
            customersTmp[i].data_prossimo_pagamento = null;
            var paymentMoment = null;
            for (var j = 0; j < customersTmp[i].serviceCustomerData.length; j++) {
              for (var k = 0; k < customersTmp[i].serviceCustomerData[j].arrayTranche.length; k++) {
                if (!customersTmp[i].serviceCustomerData[j].arrayTranche[k].paid) {
                  var newPaymentMoment = moment(customersTmp[i].serviceCustomerData[j].arrayTranche[k].paymentDate).startOf("day");
                  if (paymentMoment == null || paymentMoment > newPaymentMoment) {
                    customersTmp[i].data_prossimo_pagamento = customersTmp[i].serviceCustomerData[j].arrayTranche[k].paymentDate;
                    paymentMoment = newPaymentMoment;
                  }
                }
              }
            }
            if (customersTmp[i].data_prossimo_pagamento != null) {
              var todayDate = moment(new Date()).startOf("day");
              var diff = moment.duration(paymentMoment.diff(todayDate)).asDays();
              customersTmp[i].late_payment = diff < 0;
            } else {
              customersTmp[i].late_payment = false;
            }
          }
        }

	 
		
        return {
          data: customersTmp,
          error: null,
          meta: null,
          status: result.status,
        };
      } catch (err) {
        return {
          data: null,
          error: err,
          meta: null,
          status: null,
        };
      } finally {
        unregisterPendingRequest();
      }
    },
    [registerPendingRequest, unregisterPendingRequest]
  );

	return (
		<CustomerContext.Provider
			value={{
				getCustomers,
				getSingleCustomer,
				newCustomer,
				editCustomer,
				deleteCustomer,
				uploadFileCustomer,
				downloadFileCustomer,
				deleteFileCustomer,
				getCustomersForPractice,
				listPracticesByCustomer,
				assignToOwner,
				assignToSales,
				assignToBroker,
				changeCustomerStatus,
				getCustomerActivities,
				getCustomerMemos,
				uploadProspects,
				getCustomerFiles,
				addCustomerStrike,
				addRelative,
				removeRelative,
				getFilteredCustomers,
				generateCSVFromTable
			}}
			{...props}
		/>
	)
}
const useCustomer = () => React.useContext(CustomerContext)

export { CustomerProvider, useCustomer }
import { useEffect, useState } from "react";
import DocumentStepElement from "./step-elements/document-step-element/document-step-element";
import SurveyStepElement from "./step-elements/survey-step-element/survey-step-element";
import ReportsStepElement from "./step-elements/reports-step-element/reports-step-element";
import ActivityModalContent from "../../../ui-components/ethos-modal/activity-modal-content/activity-modal-content";
import { useEthosNotification } from "../../../contexts/ethos-notification-context";
import { useActivities } from "../../../contexts/activities-context";
import "../service-customer-step/service-customer-no-step.css";
import NotesStepElements from "./step-elements/notes-step-elements/notes-step-elements";

const ServiceCustomerNoStep = ({
  step,
  service,
  fileTypes,
  customerDocuments = [],
  doUpdateSurvey,
  doUpdateNotes,
  setCompleted,
  doDownloadFile,
  doUploadAndAssociateFile,
  doAssociateFiles,
  doRemoveFileFromService,
  doGenerateServiceCustomerReport,
  doSendServiceCustomerReport,
  activityData,
  sectionTitle,
}) => {
  const [canProceed, setCanProceed] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [activityDatas, setActivityDatas] = useState(null);
  const { showSuccessToast, handleError } = useEthosNotification();
  const { saveNewActivity } = useActivities();

  useEffect(() => {
    const checkSurveyResponseValid = (question) => {
      if (question.responseType === "Array") {
        if (!question.response || !question.response.length) {
          return !question.required;
        }
        for (var j = 0; j < question.response.length; j++) {
          for (var k = 0; k < question.response[j].length; k++) {
            if (!checkSurveyResponseValid(question.response[j][k])) {
              return false;
            }
          }
        }
        return true;
      }

      if (question.required) {
        return !!question.response;
      }
      return true;
    };

    if (step.requiredDocs && step.requiredDocs.length) {
      for (let i = 0; i < step.requiredDocs.length; i++) {
        const docType = step.requiredDocs[i];
        if (!step.documents || step.documents.filter((d) => d.docType === docType).length === 0) {
          setCanProceed(false);
          return;
        }
      }
    }

    if (step.survey && step.survey.length) {
      for (let i = 0; i < step.survey.length; i++) {
        if (!checkSurveyResponseValid(step.survey[i])) {
          setCanProceed(false);
          return;
        }
      }
    }

    if (step.requiredReports && step.requiredReports.length) {
      if (!step.reports) {
        setCanProceed(false);
        return;
      }
      for (let i = 0; i < step.requiredReports.length; i++) {
        const docType = step.requiredReports[i];
        var rep = step.reports.find((x) => x.docType === docType);
        if (!rep) {
          setCanProceed(false);
          return;
        }
      }
    }
    setCanProceed(true);
  }, [step]);

  const doSetCompleted = (step) => {
    if (canProceed) {
      setCompleted(step);
    }
  };

  const doUploadAndAssociateFileInner = (file, filename, expirationDate, type) => {
    doUploadAndAssociateFile(file, filename, expirationDate, type, step.key);
  };

  const doAssociateFilesInner = (files, type) => {
    var fileIds = files.map((x) => x._id);
    doAssociateFiles(fileIds, type, step.key);
  };

  const doGenerateServiceCustomerReportInner = async (templateId) => {
    await doGenerateServiceCustomerReport(templateId, step.key);
  };

  const updateSurvey = (survey) => {
    doUpdateSurvey(survey, step.key);
  };

  const updateNotes = (notes) => {
        doUpdateNotes(notes, step.key);
  };

  const addCustomerActivity = async (newActivity) => {
    const { error } = await saveNewActivity(newActivity);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Attività registrata correttamente");
  };

  useEffect(() => {
    if (activityData !== null) {
      setActivityDatas({ ...activityData });
    }
  }, [activityData]);

  return (
    <>
      {step.documents && activityData !== null && (
        <>
          <div className="documentsCustomer">
            <h4 style={{ textAlign: "center" }}>{sectionTitle}</h4>
            <DocumentStepElement
              readOnly={step.stepState === "COMPLETED"}
              keyName={"step_" + step.order + "_docs"}
              customerDocuments={customerDocuments}
              fileTypes={fileTypes}
              requiredDocs={step.requiredDocs ? step.requiredDocs : []}
              serviceFiles={step.documents}
              uploadAndAssociateFile={doUploadAndAssociateFileInner}
              associateFiles={doAssociateFilesInner}
              doRemoveFileFromService={doRemoveFileFromService}
              downloadFile={doDownloadFile}
              overrideClass={{ row: { class: "row" }, input: { class: "col-4" } }}
            />
          </div>
          <div className="activityCustomer">
            {!!activityDatas && (
              <>
                <ActivityModalContent
                  customParams={{
                    configurations: activityDatas.configurations,
                    customer: activityDatas.customer,
                    activity: undefined,
                    customerId: activityDatas.customer._id,
                    ownerId: null,
                    users: activityDatas.users,
                  }}
                  isModal={false}
                  confirm={addCustomerActivity}
                />
              </>
            )}
          </div>
        </>
      )}
      {step.survey && step.survey.length && (
        <>
          <h4 style={{ textAlign: "center" }}>{sectionTitle}</h4>
          <SurveyStepElement
            readOnly={step.stepState === "COMPLETED"}
            survey={step.survey}
            updateSurvey={updateSurvey}
            setUnsavedChanges={setUnsavedChanges}
            overrideClass={{ row: { class: "row" }, survey: { class: "col-12 px-4" }, input: { class: "col-12 col-md-4" } }}
          />
          <hr />
        </>
      )}

        {
            step.key === "notes" && (
                <>
                    <NotesStepElements sectionTitle={sectionTitle} oldNotes={step.notes[0]} updateNotes={updateNotes}/>
                    <hr/>
                </>
            )
        }

      {step.reports && (
        <>
          <h4 style={{ textAlign: "center" }}>{sectionTitle}</h4>
          <ReportsStepElement
            readOnly={step.stepState === "COMPLETED"}
            service={service}
            step={step}
            generateReport={doGenerateServiceCustomerReportInner}
            sendReport={doSendServiceCustomerReport}
            overrideClass={{ row: { class: "row" }, input: { class: "col-4" } }}
          />
          <hr />
        </>
      )}

      <div className="row">
        <div className="col-8 offset-2 text-center my-3">
          <div className="w-100">
            {step.stepState === "ALWAYSACTIVE" ? null : (
              <>
                {step.stepState !== "COMPLETED" ? (
                  <button disabled={!canProceed || unsavedChanges} onClick={() => doSetCompleted(step)} className="btn btn-secondary">
                    Completa Step
                  </button>
                ) : (
                  <>
                    <>
                      <button type="button" disabled className="btn btn-success">
                        Step completato
                      </button>
                    </>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCustomerNoStep;

import React, { useState, useEffect } from "react";

import { usePracticesCqs } from "../../contexts/practices-cqs-context";
import { usePracticesDebts } from "../../contexts/practices-debts-context";
import { usePracticesLoans } from "../../contexts/practices-loans-context";
import { useServiceCustomer } from "../../contexts/service-customer-context";
import { DeleteReportType } from "../../models";

const SummaryPractices = () => {
  const { getCqsSummary } = usePracticesCqs();
  const { getDebtsSummary } = usePracticesDebts();
  const { getLoansSummary } = usePracticesLoans();
  const { getScreeningSummary } = useServiceCustomer();
  const { getServiceCustomerSummary } = useServiceCustomer();

  const [cqs, setCqs] = useState(null);
  const [loans, setLoans] = useState(null);
  const [debts, setDebts] = useState(null);
  const [screening, setScreening] = useState(null);
  const [deleteReportAll, setDeleteReportAll] = useState(null);

  useEffect(() => {
    const loadSummaries = async () => {
      try {
        const [cqsSummary, loansSummary, debtsSummary, screeningSummary, deleteReport] = await Promise.all([
          getCqsSummary(),
          getLoansSummary(),
          getDebtsSummary(),
          getScreeningSummary(),
          getServiceCustomerSummary(DeleteReportType.DELETEREPORTALL, true),
        ]);

        setCqs(cqsSummary.data);
        setLoans(loansSummary.data);
        setDebts(debtsSummary.data);
        setScreening(screeningSummary.data);
        setDeleteReportAll(deleteReport.data);
      } catch (error) {
        console.error("Error loading", error);
      }
    };

    loadSummaries();
  }, [getCqsSummary, getDebtsSummary, getLoansSummary, getScreeningSummary, getServiceCustomerSummary]);

  return (
    <div className="practices-home-page">
      <div className="row">
        <div className="col-12 mb-3 text-center">
          <h3>Pratiche</h3>
        </div>
        {cqs !== null ? (
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <h5 className="fw-bold">Pratiche CQS</h5>
            <hr />
            <div style={{ overflow: "auto", maxHeight: 280 }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Stato Pratica</th>
                    <th>N. Pratiche</th>
                  </tr>
                </thead>
                <tbody>
                  {cqs.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {loans !== null ? (
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <h5 className="fw-bold">Pratiche Prestito Personale</h5>
            <hr />
            <div style={{ overflow: "auto", maxHeight: 280 }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Stato Pratica</th>
                    <th>N. Pratiche</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {debts !== null ? (
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <h5 className="fw-bold">Pratiche Gestione Debito</h5>
            <hr />
            <div style={{ overflow: "auto", maxHeight: 280 }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Stato Pratica</th>
                    <th>N. Pratiche</th>
                  </tr>
                </thead>
                <tbody>
                  {debts.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {screening !== null ? (
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <h5 className="fw-bold">Pratiche Screening</h5>
            <hr />
            <div style={{ overflow: "auto", maxHeight: 280 }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Stato Pratica</th>
                    <th>N. Pratiche</th>
                  </tr>
                </thead>
                <tbody>
                  {screening.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {deleteReportAll !== null ? (
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <h5 className="fw-bold">Pratiche Cancellazioni</h5>
            <hr />
            <div style={{ overflow: "auto", maxHeight: 280 }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Stato Pratica</th>
                    <th>N. Pratiche</th>
                  </tr>
                </thead>
                <tbody>
                  {deleteReportAll.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SummaryPractices;

import React, { useEffect, useReducer, createContext, useCallback } from "react";
import EthosModal from "../ui-components/ethos-modal/ethos-modal";
import { getRandomString } from "../utils/functions";

const EthosModalContext = createContext();

const initialModalsState = { modals: [] };
function reducer(state, action) {
  var newModals = null;
  switch (action.type) {
    case "add_first_modal":
      newModals = state.modals;
      var emptyModal = {
        id: "ethos_modal_" + getRandomString(5),
        modalKey: "",
        modalClass: "",
        modalTitle: "",
        customParams: {},
        callbackFunction: () => {},
      };
      newModals.push(emptyModal);
      return { modals: newModals };
    case "delete_modal":
      newModals = state.modals.filter((x) => x.id !== action.modalId);
      return { modals: newModals };
    case "configure_and_open_modal":
      /*
                Dato che questo reducer causa un aggiornamento dello stato, è possibile che venga eseguito più di una volta, 
                pertanto introduciamo una guardia per evitare di eseguire più volte la stessa azione
             */
      var newModal = state.modals.find((x) => x.id === action.newModalId);
      if (newModal) {
        return { modals: state.modals };
      }
      newModals = state.modals;
      var idToBeOpened;
      for (var i = 0; i < newModals.length; i++) {
        if (!newModals[i].modalKey) {
          newModals[i].modalKey = action.modalKey;
          newModals[i].modalTitle = action.modalTitle;
          newModals[i].customParams = action.customParams;
          newModals[i].callbackFunction = action.callbackFunc;
          newModals[i].modalClass = action.modalClass;
          idToBeOpened = newModals[i].id;
          break;
        }
      }
      if (idToBeOpened) {
        // Predisponiamo l'apertura della prossima modale
        newModals.push({
          id: action.newModalId,
          modalKey: "",
          modalClass: "",
          modalTitle: "",
          customParams: {},
          callbackFunction: () => {},
        });

        // Apertura della modale configurata in precedenza
        const element = document.getElementById("ethosModalButton_" + idToBeOpened);
        element.click();
      }
      return { modals: newModals };
    default:
      throw new Error();
  }
}

function EthosModalContextProvider({ children }) {
  const [modalsState, dispatch] = useReducer(reducer, initialModalsState);

  useEffect(() => {
    dispatch({ type: "add_first_modal" });
  }, []);

  const deleteModal = useCallback((id) => {
    dispatch({ type: "delete_modal", modalId: id });
  }, []);

  const getNewModalId = useCallback(() => {
    while (true) {
      const id = "ethos_modal_" + getRandomString(5);
      var existingModal = modalsState.modals.find((x) => x.id === id);
      if (!existingModal) {
        return id;
      }
    }
  }, [modalsState.modals]);

  const configureAndOpenModal = useCallback(
    (modalKey, modalTitle, modalClass, customParams, callbackFunc) => {
      var newId = getNewModalId();
      dispatch({
        type: "configure_and_open_modal",
        modalKey: modalKey,
        modalTitle: modalTitle,
        modalClass: modalClass,
        customParams: customParams,
        callbackFunc: callbackFunc,
        newModalId: newId,
      });
    },
    [getNewModalId]
  );

  const openUploadFileModal = useCallback(
    (title, allowSetFilename, allowSetExpirationDate, callbackFunc, accept,customers = null) => {
      var params = {};
      params.allowSetExpirationDate = !!allowSetExpirationDate;
      params.allowSetFilename = !!allowSetFilename;
      params.accept = accept;
      params.customers = customers
      configureAndOpenModal("UploadFileModal", title ? title : "Carica il File", "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const showUploadProspectsResultModal = useCallback(
    (title, data, callbackFunc) => {
      var params = {};
      params.data = data;
      configureAndOpenModal("ShowUploadProspectsResultModal", title ? title : "Resoconto dei prospect importati", "", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openEthosConfirmModal = useCallback(
    (text, callbackFunc, callbackInput, title) => {
      var params = {};
      params.text = text;
      params.callbackInput = callbackInput;
      configureAndOpenModal("EthosConfirmModal", title ? title : "Conferma operazione", "", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openChooseNewFileModeModal = useCallback(
    (fileTypes, callbackFunc, preSelectedType, selectFromArchive = true) => {
      var params = {};
      params.fileTypes = fileTypes;
      params.preSelectedType = preSelectedType;
      params.selectFromArchive = selectFromArchive;
      configureAndOpenModal("ChooseNewFileModeModal", "Scegli la modalità di selezione del file", "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openDocumentListModal = useCallback(
    (
      fileList,
      typeFilter,
      allowDelete,
      allowSelection,
      callbackFunc,
      doDownloadFile,
      doDeleteFile,
      title,
      deleteMessage,
      singleSelection,
      customColumns,
      customActions,
      customerScreeningInfo
    ) => {
      var params = {};
      params.fileList = fileList;
      params.typeFilter = typeFilter;
      params.allowDelete = allowDelete;
      params.allowSelection = allowSelection;
      params.doDownloadFile = doDownloadFile;
      params.doDeleteFile = doDeleteFile;
      params.singleSelection = singleSelection;
      params.deleteMessage = deleteMessage;
      params.customColumns = customColumns;
      params.customActions = customActions;
      params.customerScreeningInfo = customerScreeningInfo;
      configureAndOpenModal("DocumentListModal", title ? title : "Lista documenti", "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openStepReportListModal = useCallback(
    (fileList, doDownloadFile, doSendReport, getFileInfoById, getAsyncOperationById, getAsyncOperations, title) => {
      var params = {};
      params.fileList = fileList;
      params.doDownloadFile = doDownloadFile;
      params.doSendReport = doSendReport;
      params.getFileInfoById = getFileInfoById;
      params.getAsyncOperationById = getAsyncOperationById;
      params.getAsyncOperations = getAsyncOperations;
      configureAndOpenModal("StepReportListModal", title ? title : "Lista reports", "modal-lg", params, null);
    },
    [configureAndOpenModal]
  );

  const openServiceTemplatesModal = useCallback(
    (fileList, typeFilter, doDownloadFile, doArchiveTemplate) => {
      var params = {};
      params.fileList = fileList;
      params.typeFilter = typeFilter;
      params.doDownloadFile = doDownloadFile;
      params.doArchiveTemplate = doArchiveTemplate;
      configureAndOpenModal("ServiceTemplatesModal", "Lista templates", "modal-lg", params, null);
    },
    [configureAndOpenModal]
  );

  const openNotesModal = useCallback(
    (message, title) => {
      var params = {};
      params.message = message;
      configureAndOpenModal("NotesModal", title ? title : "Note", null, params, null);
    },
    [configureAndOpenModal]
  );

  const openWriteMessageModal = useCallback(
    (message, title, label, callbackFunc) => {
      var params = {};
      params.message = message;
      params.label = label;
      configureAndOpenModal("WriteMessage", title ? title : "Scrivi il messaggio", "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openCreatePaymentsPlanModal = useCallback(
    (title, callbackFunc) => {
      var params = {};
      configureAndOpenModal("CreatePaymentsPlan", title ? title : "Crea piano di pagamento", "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openActivityModal = useCallback(
    (callbackFunc, configurations, customer, activity, customerId, ownerId, users,sendMailFunc) => {
      var params = {};
      params.configurations = configurations;
      params.customer = customer;
      params.activity = activity;
      params.customerId = customerId;
      params.ownerId = ownerId;
      params.users = users;
      params.sendMailFunc = sendMailFunc;
      var title = activity == null ? "Registra una nuova attività sul cliente" : "Modifica attività";
      configureAndOpenModal("ActivityModal", title, null, params, callbackFunc,sendMailFunc);
    },
    [configureAndOpenModal]
  );

  const openSelectUserModal = useCallback(
    (title, users, current, showAssignToMe, roleFilter, callbackFunc, placeholder, allowBlank) => {
      var params = {};
      params.users = users;
      params.current = current;
      params.showAssignToMe = showAssignToMe;
      params.roleFilter = roleFilter;
      params.placeholder = placeholder;
      params.allowBlank = allowBlank;
      configureAndOpenModal("SelectUserModal", title ? title : "Selezionare un utente", "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openLostReasonModal = useCallback(
    (title, callbackFunc) => {
      var params = {};
      configureAndOpenModal("LostReasonModal", title ? title : "Indicare una motivazione per la perdita del cliente", null, params, callbackFunc);
    },
    [configureAndOpenModal]
  );

  const openSelectCustomerModal = useCallback(
    (title, modalSize, searchCustomer, existingRelation,currentCustomerID, callbackFunc) => {
      var params = {};
      params.searchCustomer = searchCustomer;
      params.existingRelation = existingRelation;
      params.currentCustomerID=currentCustomerID
      configureAndOpenModal("SelectCustomerModal", title ? title : "Ricerca clienti", modalSize ? modalSize : "modal-lg", params, callbackFunc);
    },
    [configureAndOpenModal]
  );

    const openExpiredTranchesArrayModal = useCallback(
        (customerName,services,data) => {
            var params = {};
            params.services = services
            params.customerData = data;
            configureAndOpenModal("ExpiredTranchesArrayModal", `Pagamenti scaduti di ${customerName}`, "modal-lg modal-dialog-scrollable", params, null);
        },
        [configureAndOpenModal]
    );

  return (
    <EthosModalContext.Provider
      value={{
        openUploadFileModal,
        openEthosConfirmModal,
        openDocumentListModal,
        openChooseNewFileModeModal,
        openServiceTemplatesModal,
        openNotesModal,
        openActivityModal,
        openWriteMessageModal,
        openSelectUserModal,
        openLostReasonModal,
        openStepReportListModal,
        showUploadProspectsResultModal,
        openCreatePaymentsPlanModal,
        openSelectCustomerModal,
        openExpiredTranchesArrayModal
      }}
    >
      {children}
      {modalsState.modals.map(function (m) {
        return (
          <div key={m.id}>
            <button
              style={{ display: "none" }}
              id={"ethosModalButton_" + m.id}
              data-bs-toggle="modal"
              data-bs-target={"#" + m.id}
              className="btn btn-primary"
            >
              Apri ethos modal
            </button>
            <EthosModal
              id={m.id}
              modalClass={m.modalClass}
              modalKey={m.modalKey}
              confirm={m.callbackFunction}
              closeFunction={() => deleteModal(m.id)}
              customParams={m.customParams}
              title={m.modalTitle}
            ></EthosModal>
          </div>
        );
      })}
    </EthosModalContext.Provider>
  );
}

const useEthosModal = () => React.useContext(EthosModalContext);

export { EthosModalContextProvider, useEthosModal };

import React, { useCallback } from 'react'
import { Auth } from 'aws-amplify';
import { httpGet, httpPost, httpDelete } from '../http'
import { FILES_ENDPOINT, ASYNC_OP_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from './ethos-notification-context';
import {checkExtensionFromUrl} from "../utils/functions";

const FilesContext = React.createContext()

function FilesProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getFileInfoById = useCallback(async (fileId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${FILES_ENDPOINT}/${fileId}`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getFilesByIds = useCallback(async (fileIds,customerIds) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${FILES_ENDPOINT}`, jwtToken, { fileIds: fileIds,customerIds:customerIds });

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getFilesInfo = useCallback(async (type) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${FILES_ENDPOINT}`, jwtToken, { filter: type });

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getFullFilesInfo = useCallback(async () => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${FILES_ENDPOINT}`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const downloadFile = useCallback(async (fileId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${FILES_ENDPOINT}/download/url/` + fileId, jwtToken);

			const extractType = checkExtensionFromUrl(result.data.url)

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			if(!extractType){
            link.setAttribute('target', `_blank`);
            }
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteFile = useCallback(async (fileId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const url = `${FILES_ENDPOINT}`;
			const result = await httpDelete(url, jwtToken, fileId);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getAsyncOperationById = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${ASYNC_OP_ENDPOINT}/${id}`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getAsyncOperations = useCallback(async () => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${ASYNC_OP_ENDPOINT}`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	return (
		<FilesContext.Provider
			value={{
				downloadFile,
				deleteFile,
				getFileInfoById,
				getFilesInfo,
				getFullFilesInfo,
				getAsyncOperationById,
				getAsyncOperations,
				getFilesByIds
			}}
			{...props}
		/>
	)
}


const useFiles = () => React.useContext(FilesContext)

export { FilesProvider, useFiles }
import { useCallback, useEffect, useState } from "react";
import { useCustomer } from "../../contexts/customer-context";
import { useService } from "../../contexts/service-context";
import { useFiles } from "../../contexts/files-context";
import { useServiceCustomer } from "../../contexts/service-customer-context";
import { useEthosNotification } from "../../contexts/ethos-notification-context";
import { useConfigurations } from "../../contexts/configurations-context";
import EthosStepper from "../../ui-components/ethos-stepper/ethos-stepper";
import { getServiceCustomerName, isExpired, isServiceDeleteReport } from "../../utils/functions";
import ServiceCustomerStep from "./service-customer-step/service-customer-step";
import { useEthosModal } from "../../contexts/ethos-modal-context";
import moment from "moment";
import "./style.css";
import { useUser } from "../../contexts/user-context";

const ServiceCustomerScreening = (props) => {
  const { id } = props.match.params;
  const { configurations } = useConfigurations();
  const { getSingleCustomer } = useCustomer();
  const { downloadFile, getFilesByIds } = useFiles();
  const { getServices } = useService();
  const {
    getServiceCustomer,
    setServiceCustomerStepCompleted,
    uploadServiceCustomerDocument,
    removeServiceCustomerDocument,
    updateServiceCustomer,
    generateServiceCustomerStepReport,
    sendServiceCustomerReport,
    setServiceCustomerState,
  } = useServiceCustomer();
  const { getUsers } = useUser();
  const [users, setUsers] = useState([]);
  const { showSuccessToast, showErrorToast, handleError } = useEthosNotification();
  const { openEthosConfirmModal, openWriteMessageModal } = useEthosModal();
  const [customer, setCustomer] = useState(null);
  const [serviceCustomer, setServiceCustomer] = useState(null);
  const [services, setServices] = useState(null);
  const [service, setService] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [customerFiles, setCustomerFiles] = useState(null);
  const [relativeCustomer, setRelativeCustomer] = useState([]);
  const [optionSelectDoc, setOptionSelectDoc] = useState([]);
  const [allCustomerScreeningInfo, setAllCustomerScreeningInfo] = useState([]);

  const loadCustomer = useCallback(
    async (custId) => {
      const { data, error } = await getSingleCustomer(custId);
      if (error) {
        handleError(error);
        return;
      }
      setCustomer(data);
    },
    [getSingleCustomer, handleError]
  );

  const loadCustomerFiles = useCallback(
    async (custId, result) => {
      const arrayId = result.map((item) => {
        return item._id;
      });

      arrayId.push(custId);

      const { data, error } = await getFilesByIds(null, arrayId);
      if (error) {
        handleError(error);
        return;
      }
      setCustomerFiles(data.data);
    },
    [getFilesByIds, handleError]
  );

  const loadUsers = useCallback(async () => {
    var { data, error } = await getUsers(null, null, null, 0);
    if (error) {
      handleError(error);
      return (window.location.href = "/customers");
    }
    setUsers(data.data);
  }, [getUsers, handleError]);

  const init = async (setDefaultStep) => {
    var { data, error } = await getServiceCustomer(id);
    if (error) {
      handleError(error);
      return;
    }

    if (!data.properties || !data.properties.steps || !data.properties.steps.length) {
      showErrorToast("Errore durante il recupero dei dettagli del servizio");
      return;
    }
    setServiceCustomer(data);

    if (data) {
      const infoPromises = data.relatives.map((item) => getRelativeInfo(item.relativeId));
      const results = await Promise.all(infoPromises);
      setRelativeCustomer(results);
      if (!setDefaultStep) {
        setCurrentStep(data.properties.steps.find((x) => x.order === currentStep.order));
      } else {
        // Se è il primo caricamento della pagina, atterriamo nello step selezionato tramite la logica di default (Il primo non completato, se sono tutti completati, il primo)
        setDefaultCurrentStep(data.properties.steps);
      }

      loadCustomer(data.customerId);
      loadCustomerFiles(data.customerId, results);
      loadUsers();
    }
  };

  const setDefaultCurrentStep = (steps) => {
    var notCompleted = steps.filter((x) => x.stepState !== "COMPLETED");
    if (notCompleted.length === 0) {
      setCurrentStep(steps.find((x) => x.order === Math.min(...steps.map((x) => x.order))));
    } else {
      setCurrentStep(steps.find((x) => x.order === Math.min(...notCompleted.map((x) => x.order))));
    }
  };

  const loadServices = useCallback(async () => {
    var { data, error } = await getServices();

    if (error) {
      handleError(error);
      return;
    }
    setServices(data.data);
  }, [getServices, handleError]);

  // eslint-disable-next-line
  useEffect(() => {
    loadServices();
    init(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (services && services.length && serviceCustomer) {
      var s = services.find((e) => e._id === serviceCustomer.serviceId);
      setService(s);
    }
  }, [services, serviceCustomer]);

  const doUploadAndAssociateFile = async (file, filename, expirationDate, type, stepKey, selectedCustomer) => {
    const customerId = !!selectedCustomer ? selectedCustomer : customer._id;

    var { error } = await uploadServiceCustomerDocument(file, filename, expirationDate, type, serviceCustomer._id, stepKey, customerId);
    if (error) {
      handleError(error);
      return;
    }

    showSuccessToast("Servizio aggiornato correttamente!");
    init(false);
  };

  const doAssociateFiles = async (fileIds, type, stepKey) => {
    var newSc = { ...serviceCustomer };
    for (var i = 0; i < newSc.properties.steps.length; i++) {
      if (newSc.properties.steps[i].key === stepKey) {
        for (var j = 0; j < fileIds.length; j++) {
          var obj = {};
          obj.id = fileIds[j];
          obj.docType = type;
          newSc.properties.steps[i].documents.push(obj);
        }
        break;
      }
    }
    setServiceCustomer(newSc);
    doUpdateServiceCustomer();
  };

  const doUpdateSurvey = async (survey, stepKey) => {
    var newSc = { ...serviceCustomer };
    for (var i = 0; i < newSc.properties.steps.length; i++) {
      if (newSc.properties.steps[i].key === stepKey) {
        newSc.properties.steps[i].survey = survey;
        break;
      }
    }
    setServiceCustomer(newSc);
    doUpdateServiceCustomer();
  };

  const doRemoveFileFromService = async (fileId) => {
    var { error } = await removeServiceCustomerDocument(serviceCustomer._id, fileId);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Servizio aggiornato correttamente!");
    init(false);
  };

  const doGenerateServiceCustomerReport = async (templateKey, stepKey) => {
    var { error } = await generateServiceCustomerStepReport(serviceCustomer._id, templateKey, stepKey);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Report generato correttamente!");
    init(false);
  };

  const doSendServiceCustomerReport = async (reportId) => {
    var callback = async (body) => {
      var subject = "Report servizio: " + getServiceCustomerName(services, serviceCustomer);
      var { error } = await sendServiceCustomerReport(serviceCustomer._id, body, subject, reportId);
      if (error) {
        handleError(error);
        return;
      }
      showSuccessToast("Report inviato correttamente!");
      init(false);
    };
    openWriteMessageModal(
      "In allegato il report per il servizio in oggetto.\n\nDistinti Saluti",
      "Scrivi il messaggio a cui allegare il report",
      null,
      callback
    );
  };

  const doDownloadFile = async (fileId) => {
    const { error } = await downloadFile(fileId);
    if (error !== null) {
      showErrorToast("Errore durante il download del file!");
      return;
    }
    // startDownload(data);
  };

  const doUpdateServiceCustomer = async () => {
    const { error } = await updateServiceCustomer(serviceCustomer);
    if (error !== null) {
      handleError(error);
      return;
    }
    showSuccessToast("Servizio aggiornato correttamente!");
    init(false);
  };

  const doCompleteStep = (step) => {
    const completeStepCallback = async (inp) => {
      const { error } = await setServiceCustomerStepCompleted(serviceCustomer, step.key);
      if (error !== null) {
        handleError(error);
        return;
      }
      showSuccessToast("Servizio aggiornato correttamente!");
      init(true);
    };

    openEthosConfirmModal("Sicuro di voler marcare questo step come completato?", completeStepCallback, {}, "Conferma aggiornamento servizio");
  };

  const doArchiveSreening = () => {
    const archiveScreeningCallback = async (inp) => {
      const { error } = await setServiceCustomerState(serviceCustomer, "ARCHIVED");
      if (error !== null) {
        handleError(error);
        return;
      }
      showSuccessToast("Servizio aggiornato correttamente!");
      init(true);
    };

    openEthosConfirmModal("Sicuro di voler archiviare il servizio di screening?", archiveScreeningCallback, {}, "Conferma archiviazione");
  };

  const isLoaded = () => {
    return customer && services && users && serviceCustomer && configurations && customerFiles && relativeCustomer;
  };

  const getRelativeInfo = useCallback(
    async (id) => {
      try {
        const { data, error } = await getSingleCustomer(id);

        if (error) {
          return { _id: "-1", first_name: "Cliente", last_name: "Eliminato" };
        }

        return { _id: id, first_name: data.first_name, last_name: data.last_name };
      } catch (error) {
        console.log(error);
      }
    },
    [getSingleCustomer]
  );

  /*   const doSetServiceCustomerState = async (serviceCust, state) => {
    var { error } = await setServiceCustomerNewState(serviceCust, state);
    if (error) {
      handleError(error);
      return;
    }
    window.location.reload();
  }; */

  useEffect(() => {
    if (customer && relativeCustomer) {
      /* Costruzione Option */
      const options = relativeCustomer.map(({ _id, first_name, last_name }) => ({
        value: _id,
        label: `${first_name} ${last_name}`,
      }));

      options.push({
        value: customer._id,
        label: `${customer.first_name} ${customer.last_name}`,
      });

      setAllCustomerScreeningInfo([...relativeCustomer, { _id: customer._id, first_name: customer.first_name, last_name: customer.last_name }]);
      setOptionSelectDoc(options);
    }
  }, [customer, relativeCustomer]);

  return (
    <>
      {isLoaded() ? (
        <div>
          <div className="row">
            <div className="col-12 text-center">
              <h3>Dettagli servizio: {getServiceCustomerName(services, serviceCustomer)}</h3>
              <h6>
                Cliente:{" "}
                <a href={"/customers/" + serviceCustomer.customerId}>
                  <span className="fw-bold">{customer.first_name + " " + customer.last_name}</span>
                </a>
              </h6>
              {relativeCustomer && relativeCustomer.length > 0 && <h6>Soggetti Associati:</h6>}
            </div>

            <div className="col-12 text-center ">
              {isServiceDeleteReport(services, serviceCustomer) && (
                <>
                  <span className="d-block my-3">
                    Stato Avanzamento:{" "}
                    <span style={{ backgroundColor: "grey", padding: "0.5rem", borderRadius: "0.5rem", color: "white" }}>
                      {serviceCustomer.serviceStatus.status}
                    </span>
                    <span className="d-block mt-3">
                      Data Scadenza:{" "}
                      <span
                        className={isExpired(
                          serviceCustomer.serviceStatus === null || serviceCustomer.serviceStatus.expireAt === null
                            ? "N.D."
                            : moment(serviceCustomer.serviceStatus.expireAt).format("DD/MM/YYYY")
                        )}
                      >
                        {serviceCustomer.serviceStatus === null || serviceCustomer.serviceStatus.expireAt === null
                          ? "N.D."
                          : moment(serviceCustomer.serviceStatus.expireAt).format("DD/MM/YYYY")}
                      </span>
                    </span>
                  </span>
                </>
              )}
            </div>
            <div className="col-12 text-center">
              {relativeCustomer
                ? relativeCustomer.map((item, index) => {
                    return (
                      <span key={item._id}>
                        <a href={"/customers/" + item._id}>
                          <span className="fw-bold">{`${item.first_name} ${item.last_name}`}</span>
                        </a>
                        {`${relativeCustomer.length - 1 === index ? "" : ", "}`}
                      </span>
                    );
                  })
                : null}
            </div>

            <div className="col-12">
              <EthosStepper steps={serviceCustomer.properties.steps} currentStep={currentStep} setCurrentStep={setCurrentStep}></EthosStepper>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-10 offset-1">
                  {currentStep ? (
                    <ServiceCustomerStep
                      service={service}
                      fileTypes={configurations["typeFile"]}
                      customerDocuments={customerFiles}
                      step={currentStep}
                      doUploadAndAssociateFile={doUploadAndAssociateFile}
                      doUpdateSurvey={doUpdateSurvey}
                      doAssociateFiles={doAssociateFiles}
                      doDownloadFile={doDownloadFile}
                      doRemoveFileFromService={doRemoveFileFromService}
                      doGenerateServiceCustomerReport={doGenerateServiceCustomerReport}
                      doSendServiceCustomerReport={doSendServiceCustomerReport}
                      setCompleted={doCompleteStep}
                      customerId={serviceCustomer.customerId}
                      doArchiveSreening={doArchiveSreening}
                      currentServiceStatus={serviceCustomer.status}
                      docOption={optionSelectDoc}
                      customerScreeningInfo={allCustomerScreeningInfo}
                      dataActivity={{ configurations, customer, users }}
                    ></ServiceCustomerStep>
                  ) : (
                    <h6>Selezionare uno step da visualizzare...</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-center">Caricamento in corso...</h3>
        </div>
      )}
    </>
  );
};

export default ServiceCustomerScreening;

import { useCustomer } from "../../contexts/customer-context";
import { useService } from "../../contexts/service-context";
import { useFiles } from "../../contexts/files-context";
import { useServiceCustomer } from "../../contexts/service-customer-context";
import { useEthosNotification } from "../../contexts/ethos-notification-context";
import { useConfigurations } from "../../contexts/configurations-context";
import { useEthosModal } from "../../contexts/ethos-modal-context";
import { useCallback, useEffect, useState } from "react";
import { getServiceCustomerName, getServiceInternalName, isExpired } from "../../utils/functions";
import ServiceCustomerNoStep from "./service-customer-step/service-customer-no-step";
import { useUser } from "../../contexts/user-context";

import moment from "moment";
import React from "react";

interface ServiceCustomerDetailsProps {
  match: {
    params: {
      id: string;
    };
  };
}

const ServiceCustomerDetails = (props: ServiceCustomerDetailsProps) => {
  const { id } = props.match.params;
  const { configurations } = useConfigurations();
  const { getServices } = useService();
  const { showSuccessToast, showErrorToast, handleError } = useEthosNotification();
  const { openEthosConfirmModal, openWriteMessageModal } = useEthosModal();
  const {
    getServiceCustomer,
    uploadServiceCustomerDocument,
    setServiceCustomerStepCompleted,
    generateServiceCustomerStepReport,
    sendServiceCustomerReport,
    removeServiceCustomerDocument,
    updateServiceCustomer,
    setServiceCustomerNewState,
  } = useServiceCustomer();
  const { getSingleCustomer, getCustomerFiles } = useCustomer();
  const { downloadFile } = useFiles();
  const { getUsers } = useUser();

  const [customer, setCustomer] = useState(null);
  const [statusProgressBlock, setStatusProgressBlock] = useState<string>("default");
  const [service, setService] = useState(null);
  const [services, setServices] = useState(null);
  const [serviceCustomer, setServiceCustomer] = useState(null);
  const [customerFiles, setCustomerFiles] = useState(null);
  const [users, setUsers] = useState([]);
  // const [currentStep, setCurrentStep] = useState(null);
  const [surveySteps, setSurveySteps] = useState(null);
  const [docSteps, setDocSteps] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("N/D");

  const loadCustomer = useCallback(
    async (customerId: string) => {
      const { data, error } = await getSingleCustomer(customerId);
      if (error) {
        handleError(error);
        return;
      }
      setCustomer(data);
    },
    [getSingleCustomer, handleError]
  );

  const loadCustomerFiles = useCallback(
    async (customerId: string) => {
      const { data, error } = await getCustomerFiles(customerId);
      if (error) {
        handleError(error);
        return;
      }
      setCustomerFiles(data);
    },
    [getCustomerFiles, handleError]
  );

  const loadServices = useCallback(async () => {
    const { data, error } = await getServices();
    if (error) {
      handleError(error);
      return;
    }
    setServices(data.data);
  }, [getServices, handleError]);

  const setDefaultCurrentStep = useCallback((steps) => {
    // var notCompleted = steps.filter((x) => x.stepState !== "COMPLETED");
    // if (notCompleted.length === 0) {
    //   setCurrentStep(steps.find((x) => x.order === Math.min(...steps.map((x) => x.order))));
    // } else {
    //   setCurrentStep(steps.find((x) => x.order === Math.min(...notCompleted.map((x) => x.order))));
    // }
  }, []);

  const loadUsers = useCallback(async () => {
    var { data, error } = await getUsers(null, null, null, 0);
    if (error) {
      handleError(error);
      return (window.location.href = "/customers");
    }
    setUsers(data.data);
  }, [getUsers, handleError]);

  const init = useCallback(
    async () => {
      const { data, error } = await getServiceCustomer(id);
      if (error) {
        handleError(error);
        return;
      }

      if (!data.properties || !data.properties.steps || !data.properties.steps.length) {
        showErrorToast("Errore durante il recupero dei dettagli del servizio");
        return;
      }

      setServiceCustomer(data);

      setSurveySteps(
        data.properties.steps.filter((item) => {
          return item.key !== "uploadClientDoc";
        })
      );

      setDocSteps(
        data.properties.steps.filter((item) => {
          return item.key === "uploadClientDoc";
        })
      );

      loadCustomer(data.customerId);
      loadCustomerFiles(data.customerId);
      loadUsers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getServiceCustomer, handleError, id, loadCustomer, loadCustomerFiles, setDefaultCurrentStep, showErrorToast]
  );

  const doUploadAndAssociateFile = async (file, filename, expirationDate, type, stepKey) => {
    var { error } = await uploadServiceCustomerDocument(file, filename, expirationDate, type, serviceCustomer._id, "uploadClientDoc", customer._id);
    if (error) {
      handleError(error);
      return;
    }

    showSuccessToast("Servizio aggiornato correttamente!");
    init();
  };

  const doAssociateFiles = async (fileIds, type, stepKey) => {
    var newSc = { ...serviceCustomer };
    for (var i = 0; i < newSc.properties.steps.length; i++) {
      if (newSc.properties.steps[i].key === stepKey) {
        for (var j = 0; j < fileIds.length; j++) {
          var obj = { id: "", docType: "" };
          obj.id = fileIds[j];
          obj.docType = type;
          newSc.properties.steps[i].documents.push(obj);
        }
        break;
      }
    }
    setServiceCustomer(newSc);
    doUpdateServiceCustomer();
  };

  const doRemoveFileFromService = async (fileId) => {
    var { error } = await removeServiceCustomerDocument(serviceCustomer._id, fileId);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Servizio aggiornato correttamente!");
    init();
  };

  const doDownloadFile = async (fileId) => {
    const { error } = await downloadFile(fileId);
    if (error !== null) {
      showErrorToast("Errore durante il download del file!");
      return;
    }
    // startDownload(data);
  };

  const doUpdateServiceCustomer = async () => {
    const { error } = await updateServiceCustomer(serviceCustomer);
    if (error !== null) {
      handleError(error);
      return;
    }
    showSuccessToast("Servizio aggiornato correttamente!");
    init();
  };

  const doUpdateSurvey = async (survey, stepKey) => {
    var newSc = { ...serviceCustomer };
    for (var i = 0; i < newSc.properties.steps.length; i++) {
      if (newSc.properties.steps[i].key === stepKey) {
        newSc.properties.steps[i].survey = survey;
        break;
      }
    }
    setServiceCustomer(newSc);
    doUpdateServiceCustomer();
  };

    const doUpdateNotes = async (notes, stepKey) => {
        var newSc = {...serviceCustomer};
        for (var i = 0; i < newSc.properties.steps.length; i++) {
            if (newSc.properties.steps[i].key === stepKey) {
                newSc.properties.steps[i].notes = [`${notes}`];
                break;
            }
        }
        setServiceCustomer(newSc);
        doUpdateServiceCustomer();
    };

  const doCompleteStep = (step) => {
    const completeStepCallback = async (inp) => {
      const { error } = await setServiceCustomerStepCompleted(serviceCustomer, step.key);
      if (error !== null) {
        handleError(error);
        return;
      }
      showSuccessToast("Servizio aggiornato correttamente!");
      init();
    };

    openEthosConfirmModal("Sicuro di voler marcare questo step come completato?", completeStepCallback, {}, "Conferma aggiornamento servizio");
  };

  const doGenerateServiceCustomerReport = async (templateKey, stepKey) => {
    var { error } = await generateServiceCustomerStepReport(serviceCustomer._id, templateKey, stepKey);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Report generato correttamente!");
    init();
  };

  const doSendServiceCustomerReport = async (reportId) => {
    var callback = async (body) => {
      var subject = "Report servizio: " + getServiceCustomerName(services, serviceCustomer);
      var { error } = await sendServiceCustomerReport(serviceCustomer._id, body, subject, reportId);
      if (error) {
        handleError(error);
        return;
      }
      showSuccessToast("Report inviato correttamente!");
      init();
    };
    openWriteMessageModal(
      "In allegato il report per il servizio in oggetto.\n\nDistinti Saluti",
      "Scrivi il messaggio a cui allegare il report",
      null,
      callback
    );
  };

  const doSetServiceCustomerState = async (serviceCust, state) => {
    var { error } = await setServiceCustomerNewState(serviceCust, state);
    if (error) {
      handleError(error);
      return;
    }
    setCurrentStatus(state);
    showSuccessToast("Stato aggiornato correttamente!");
    init();
  };

  const isLoaded = useCallback(() => {
    return customer && services && users && serviceCustomer && configurations && customerFiles && surveySteps;
  }, [surveySteps, configurations, customer, customerFiles, users, serviceCustomer, services]);

  useEffect(() => {
    if (services && services.length && serviceCustomer) {
      var s = services.find((e) => e._id === serviceCustomer.serviceId);
      setService(s);
    }
  }, [services, serviceCustomer]);

  useEffect(() => {
    if (serviceCustomer && serviceCustomer.serviceStatus) {
      setCurrentStatus(serviceCustomer.serviceStatus.status);
    }
  }, [serviceCustomer]);

  useEffect(() => {
    loadServices();
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (services && serviceCustomer) {
      const internalName = getServiceInternalName(services, serviceCustomer);
      switch (internalName) {
        case "CancellazioneSegnalazioniSic":
          setStatusProgressBlock("cancellazioneSegnalazioniSic");
          break;
        case "CancellazioneSegnalazioniProtesti":
          setStatusProgressBlock("cancellazioneSegnalazioniProtesti");
          break;
      }
    }
  }, [services, serviceCustomer]);

  return (
    <>
      {isLoaded() ? (
        <div className="no-step-details">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Dettagli servizio: {getServiceCustomerName(services, serviceCustomer)}</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="d-flex col-12 justify-content-center py-3 align-items-center gap-3 serviceInfo">
              <div className="serviceInfo_element">
                <h6 style={{ marginBottom: "0" }}>
                  CLIENTE:{" "}
                  <a href={"/customers/" + serviceCustomer.customerId}>
                    <span className="fw-bold">{customer.first_name + " " + customer.last_name}</span>
                  </a>
                </h6>
              </div>
              <span className="d-block py-3 divider"></span>
              <>
                <div className="serviceInfo_element">
                  <span>STATO AVANZAMENTO: </span>
                  <div className="btn-group" role="group">
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      className="btn dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ background: "grey", color: "white" }}
                    >
                      {currentStatus}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      {configurations.typeServiceStatus[statusProgressBlock]
                        .filter((item) => item.value !== currentStatus)
                        .map((item, index) => {
                          return (
                            <li key={index}>
                              <button onClick={() => doSetServiceCustomerState(serviceCustomer, item.value)} className="dropdown-item">
                                {item.label}
                              </button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </>
              {serviceCustomer && serviceCustomer.serviceStatus && serviceCustomer.serviceStatus.expireAt && (
                <>
                  <span className="d-block py-3 divider"></span>
                  <div className="serviceInfo_element">
                    <span>DATA SCADENZA: </span>
                    <span className={isExpired(moment(serviceCustomer.serviceStatus.expireAt).format("DD/MM/YYYY"))}>
                      {moment(serviceCustomer.serviceStatus.expireAt).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 col-lg-8">
              {surveySteps
                ? surveySteps.map((item, index) => {
                    return (
                      <div key={index}>
                        <ServiceCustomerNoStep
                          sectionTitle={item.label}
                          key={serviceCustomer._id}
                          service={service}
                          fileTypes={configurations["typeFile"]}
                          customerDocuments={customerFiles}
                          step={item}
                          doUploadAndAssociateFile={doUploadAndAssociateFile}
                          doUpdateSurvey={doUpdateSurvey}
                          doAssociateFiles={doAssociateFiles}
                          doUpdateNotes={doUpdateNotes}
                          doDownloadFile={doDownloadFile}
                          doRemoveFileFromService={doRemoveFileFromService}
                          doGenerateServiceCustomerReport={doGenerateServiceCustomerReport}
                          doSendServiceCustomerReport={doSendServiceCustomerReport}
                          setCompleted={doCompleteStep}
                          activityData={null}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="col-12 col-lg-4 ">
              {docSteps
                ? docSteps.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ServiceCustomerNoStep
                          sectionTitle={item.label}
                          key={serviceCustomer._id}
                          service={service}
                          fileTypes={configurations["typeFile"]}
                          customerDocuments={customerFiles}
                          step={item}
                          doUpdateNotes={doUpdateNotes}
                          doUploadAndAssociateFile={doUploadAndAssociateFile}
                          doUpdateSurvey={doUpdateSurvey}
                          doAssociateFiles={doAssociateFiles}
                          doDownloadFile={doDownloadFile}
                          doRemoveFileFromService={doRemoveFileFromService}
                          doGenerateServiceCustomerReport={doGenerateServiceCustomerReport}
                          doSendServiceCustomerReport={doSendServiceCustomerReport}
                          setCompleted={doCompleteStep}
                          activityData={{
                            configurations,
                            customer,
                            users,
                          }}
                        />
                      </React.Fragment>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100dvh" }} className="d-flex justify-content-center align-item-center">
          <h3>Caricamento in corso...</h3>
        </div>
      )}
    </>
  );
};

export default ServiceCustomerDetails;

import { useState, useEffect, useRef } from 'react';
import { InputSelect } from '../../../../../forms';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AddServiceCustomerForm = ({ customerId, services = [], addServiceCustomer = (service) => { } }) => {

    const [selectedService, setSelectedService] = useState(null);
    const [selectebleServices, setSelectableServices] = useState([]);
    const [tranches, setTranches] = useState([]);
    const [canConfirm, setCanConfirm] = useState(false);
    const serviceRef = useRef()

    const confirm = () => {
        if (!canConfirm) {
            return;
        }
        var service = getServiceById(selectedService);
        var obj = {
            customerId: customerId,
            serviceId: selectedService,
            fee: service.configuration.fee,
            tranche: service.configuration.tranche,
            arrayTranche: tranches,
            status: "PROPOSED"
        };
        addServiceCustomer(obj);
        serviceRef.current.value = "";
        setSelectedService(null);
    }

    const onServiceSelected = (selectedServiceId) => {
        setSelectedService(selectedServiceId)
        var service = getServiceById(selectedServiceId);
        if (service != null) {
            var newTranches = [];
            for (var i = 0; i < service.configuration.tranche; i++) {
                newTranches.push(
                    {
                        paid: false,
                        amount: (service.configuration.fee / service.configuration.tranche),
                        paymentDate: new Date(),
                        expectedPaymentDate: new Date(),
                        effectivePaymentDate: null
                    }
                );
            }
            setTranches(newTranches);
        } else {
            setTranches([]);
        }
    }

    // const setExpectedPaymentDate = (index) => {
    //     return (date) => {
    //         var newTranches = tranches.map(function (t) { return t });
    //         newTranches[index].paymentDate = date;
    //         setTranches(newTranches);
    //     }
    // }

    const getServiceById = (id) => {
        return services.find(s => s._id === id);
    }

    useEffect(() => {
        var servs = services.map(s => {
            s.label = s.displayName;
            s.value = s._id;
            return s;
        });
        setSelectableServices(servs);
        setTranches([]);
        setSelectedService(null);
    }, [services]);

    useEffect(() => {
        var can = true;
        if (!selectedService) {
            can = false;
        }

     /*    if (!tranches || !tranches.length) {
            can = false;
        } */
        setCanConfirm(can)
    }, [selectedService, tranches]);

    return (
        <div className='d-flex flex-row mb-2 justify-content-left align-items-center'>
            <label className='text-nowrap'>Aggiungi servizio</label>
            <InputSelect
                className='mx-4'
                register={() => serviceRef}
                defaultValue={''}
                name="service"
                data={selectebleServices}
                onChange={onServiceSelected}
                placeholder="Seleziona un servizio" />
            <div className="col-3">
                <button type="button" onClick={confirm} className={classNames(((!canConfirm) ? "disabled" : ""), " btn btn-primary")}>
                    <FontAwesomeIcon className='mr-4' icon={faPlus} />
                    Aggiungi
                </button>
            </div>
        </div>
    )
}

export default AddServiceCustomerForm;
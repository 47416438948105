import {InputTextArea} from "../../../../../ui-components/forms";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

interface NotesStepElementsProps {
    sectionTitle: string
    oldNotes: string
    updateNotes: (notes: string) => void
}

const NotesStepElements = ({sectionTitle, oldNotes, updateNotes}: NotesStepElementsProps) => {
    const [note, setNote] = useState<string | null>(null)

    const handleUpdateNotes = () => {
        updateNotes(note)
    }

    useEffect(() => {
        if (oldNotes) {
            setNote(oldNotes)
        }
    }, [oldNotes]);

    return (
        <div className="row">


            <div className="col-12">
                <button
                    onClick={handleUpdateNotes}
                    className={"btn btn-primary"}
                >
                    <FontAwesomeIcon className="mr-2" icon={faSave}/>
                    Salva Note
                </button>
            </div>

            <h4 className={"mt-3"} style={{textAlign: "center"}}>{sectionTitle}</h4>
            <div className="col-12">
                <InputTextArea name="notes" label="Note cancellazione" defaultValue={note}
                               onChange={(e: string) => {
                                   setNote(e)
                               }}

                               customrow={5}/>
            </div>
        </div>
    )
};

export default NotesStepElements
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faFileInvoice,
  faSignOutAlt,
  faArchive,
  faUniversity,
  faWrench,
  faSearch,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "./sidebar.css";

const Sidebar = ({ role, signOut }) => {
  const { user } = useAuthenticator();

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {expanded && <div onClick={() => setExpanded(false)} className={"sidebar-overlay"}></div>}
      <div className="ethos-navbar">
        <div className="row navbar-row text-white">
          <div onClick={() => setExpanded(true)} style={{ fontSize: "1.5em" }} className="col-1 text-center">
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div className="col-10 text-center">
            <span className="navbar-title">
              <NavLink to="/home">ETHOS</NavLink>
            </span>
          </div>
        </div>
      </div>
      <div className={"sidebar-main" + (expanded ? " sidebar-expanded" : "")}>
        <div className="d-flex flex-column h-100">
          <NavLink onClick={() => setExpanded(false)} to="/home">
            ETHOS
          </NavLink>
          <hr />
          <div>
            <>
              <NavLink onClick={() => setExpanded(false)} to="/activities">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faCalendarDay} />
                  <span>Attività</span>
                </span>
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} to="/customers">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faUser} />
                  <span>Clienti</span>
                </span>
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} to="/banks">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faUniversity} />
                  <span>Banche</span>
                </span>
              </NavLink>
              {role === "ADMIN" && (
                <NavLink onClick={() => setExpanded(false)} to="/screenings">
                  <span className="sidebar-link">
                    <FontAwesomeIcon icon={faSearch} />
                    <span>Screening</span>
                  </span>
                </NavLink>
              )}
              {role === "ADMIN" && (
                <NavLink onClick={() => setExpanded(false)} to="/delete-report">
                  <span className="sidebar-link">
                    <FontAwesomeIcon icon={faFileInvoice} />
                    <span>Cancellazione segnalazioni</span>
                  </span>
                </NavLink>
              )}
              <NavLink onClick={() => setExpanded(false)} to="/practices-cqs">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span>Cessione del Quinto</span>
                </span>
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} to="/practices-loans">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span>Recuperi PP-Mut-Carte</span>
                </span>
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} to="/practices-debts">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span>Gestione Debito</span>
                </span>
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} to="/archives?path=/">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faArchive} />
                  <span>Archivio</span>
                </span>
              </NavLink>
              <NavLink onClick={() => setExpanded(false)} to="/archives/my-file?path=/Altri-File/">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faArchive} />
                  <span>File</span>
                </span>
              </NavLink>
              {/* <NavLink onClick={() => setExpanded(false)} to="/tickets">
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faComments} />
                  <span>Ticket</span>
                </span>
              </NavLink> */}
            </>

            {role === "ADMIN" ? (
              <>
                {/* <NavLink onClick={() => setExpanded(false)} to="/database">
										<span className="sidebar-link">
											<FontAwesomeIcon icon={faDatabase} />
											<span>Database</span>
										</span>
									</NavLink> */}
                {/* <NavLink onClick={() => setExpanded(false)} to="/users">
										<span className="sidebar-link">
											<FontAwesomeIcon icon={faUsers} />
											<span>Utenti</span>
										</span>
									</NavLink> */}
                <NavLink onClick={() => setExpanded(false)} to="/services-configuration">
                  <span className="sidebar-link">
                    <FontAwesomeIcon icon={faWrench} />
                    <span>Configura servizi</span>
                  </span>
                </NavLink>
              </>
            ) : null}
          </div>

          <div className="sidebar-account mt-auto text-white">
            <p>Ciao {user.username}</p>
            <div className="sidebar-link" href="#" onClick={signOut}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Disconetti</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

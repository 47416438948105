import EthosConfirmModalContent from './ethos-confirm-modal-content/ethos-confirm-modal-content';
import UploadFileModalContent from './upload-file-modal-content/upload-file-modal-content';
import DocumentListModalContent from './document-list-modal-content/document-list-modal-content';
import ChooseNewFileModeModalContent from './choose-new-file-mode-modal-content/choose-new-file-mode-modal-content';
import ServiceTemplatesModalContent from './service-templates-modal-content/service-templates-modal-content';
import NotesModalContent from './notes-modal-content/notes-modal-content';
import ActivityModalContent from './activity-modal-content/activity-modal-content';
import WriteMessageModalContent from './write-message-modal-content/write-message-modal-content';
import SelectUserModalContent from './select-user-modal-content/select-user-modal-content';
import LostReasonModalContent from './lost-reason-modal-content/lost-reason-modal-content';
import StepReportListModalContent from './step-report-list-modal-content/step-report-list-modal-content';
import ShowUploadProspectsResultModal from './show-upload-prospects-result-modal';
import CreatePaymentsPlanModalContent from './create-payments-plan-modal-content';
import SelectCustomerModalContent from './select-customers-modal-content/select-customers-modal-content';
import ExpiredTranchesArrayModalContent from "./expired-tranches-array-modal-content/expired-tranches-array-modal-content";

const EthosModal = ({ id, modalClass = "", confirm = () => { }, modalKey, closeFunction = () => { }, customParams = {}, title }) => {
	return (
		<div className="modal fade" id={id} tabIndex="2000" data-bs-backdrop="static" aria-labelledby={id} aria-hidden="true">
			<div className={"modal-dialog " + modalClass}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{title}</h5>
						<button onClick={closeFunction} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					{modalKey === "UploadFileModal" && <UploadFileModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></UploadFileModalContent>}
					{modalKey === "ShowUploadProspectsResultModal" && <ShowUploadProspectsResultModal customParams={customParams} confirm={confirm} closeFunction={closeFunction}></ShowUploadProspectsResultModal>}
					{modalKey === "EthosConfirmModal" && <EthosConfirmModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></EthosConfirmModalContent>}
					{modalKey === "DocumentListModal" && <DocumentListModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></DocumentListModalContent>}
					{modalKey === "ChooseNewFileModeModal" && <ChooseNewFileModeModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></ChooseNewFileModeModalContent>}
					{modalKey === "ServiceTemplatesModal" && <ServiceTemplatesModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></ServiceTemplatesModalContent>}
					{modalKey === "NotesModal" && <NotesModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></NotesModalContent>}
					{modalKey === "ActivityModal" && <ActivityModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></ActivityModalContent>}
					{modalKey === "WriteMessage" && <WriteMessageModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></WriteMessageModalContent>}
					{modalKey === "SelectUserModal" && <SelectUserModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></SelectUserModalContent>}
					{modalKey === "LostReasonModal" && <LostReasonModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></LostReasonModalContent>}
					{modalKey === "StepReportListModal" && <StepReportListModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></StepReportListModalContent>}
					{modalKey === "CreatePaymentsPlan" && <CreatePaymentsPlanModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></CreatePaymentsPlanModalContent>}
					{modalKey === "SelectCustomerModal" && <SelectCustomerModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></SelectCustomerModalContent>}
					{modalKey === "ExpiredTranchesArrayModal" && <ExpiredTranchesArrayModalContent customParams={customParams} confirm={confirm} closeFunction={closeFunction}></ExpiredTranchesArrayModalContent>}
				</div>
			</div>
		</div>
	)
}

export default EthosModal;
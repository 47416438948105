import { Route, Switch } from "react-router-dom";
import { BanksProvider } from "../contexts/banks-context";
import { CustomerProvider } from "../contexts/customer-context";
import { FilesProvider } from "../contexts/files-context";
import { ServiceProvider } from "../contexts/service-context";
import { ServiceCustomerProvider } from "../contexts/service-customer-context";
import ServiceCustomerDetails from "../components/service-customer/service-customer-details";
import { UserProvider } from "../contexts/user-context";
import { ActivitiesProvider } from "../contexts/activities-context";

const ServiceCustomerDetailsPage = ({ match }) => {
  return (
    <div>
      <BanksProvider>
        <CustomerProvider>
          <UserProvider>
            <ActivitiesProvider>
              <ServiceProvider>
                <ServiceCustomerProvider>
                  <FilesProvider>
                    <Switch>
                      <Route exact path={`${match.path}/:id`} component={ServiceCustomerDetails} />
                    </Switch>
                  </FilesProvider>
                </ServiceCustomerProvider>
              </ServiceProvider>
            </ActivitiesProvider>
          </UserProvider>
        </CustomerProvider>
      </BanksProvider>
    </div>
  );
};

export default ServiceCustomerDetailsPage;

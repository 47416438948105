import React, { useEffect, useState } from "react";
import { ServiceCustomer } from "../../../models";
import { getServiceCustomerName } from "../../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import _ from "lodash";

const ExpiredTranchesArrayModalContent = ({
  confirm = (data: any) => {},
  closeFunction = () => {},
  customParams = {
    services: [],
    customerData: {} as any,
  },
}) => {
  const [allServiceCustomerData, setAllServiceCustomerData] = useState<ServiceCustomer[] | null>(null);
  const [sortedCustomerData, setSortedCustomerData] = useState<ServiceCustomer[] | null>(null);
  const [hasOverduePayments, setHasOverduePayments] = useState(false);

  useEffect(() => {
    if (customParams.customerData) {
      setAllServiceCustomerData(customParams.customerData.serviceCustomerData);
    }
  }, [customParams.customerData]);

  useEffect(() => {
    if (allServiceCustomerData) {
      const checkOverduePayments = allServiceCustomerData.some((serviceCustomer) =>
        serviceCustomer.arrayTranche.some((x) => x.paid === false && !x.deleted && new Date(x.expectedPaymentDate) < new Date())
      );

      const processedData = allServiceCustomerData
        .map((serviceCustomer) => {
          const maxDelay = Math.max(
            ...serviceCustomer.arrayTranche
              .filter((x) => x.paid === false && !x.deleted && new Date(x.expectedPaymentDate) < new Date())
              .map((x) => moment().diff(moment(x.expectedPaymentDate), "days")),
            0 // Default se non ci sono tranche in ritardo
          );
          return { ...serviceCustomer, maxDelay };
        })
        .sort((a, b) => b.maxDelay - a.maxDelay);

      setHasOverduePayments(checkOverduePayments);
      setSortedCustomerData(processedData);
    }
  }, [allServiceCustomerData]);

  return (
    <div key={_.uniqueId()} className="modal-body">
      {hasOverduePayments ? (
        <React.Fragment key={_.uniqueId()}>
          <h4 className={"text-center mb-3"}>Tranche scadute</h4>
          {sortedCustomerData.map((serviceCustomer, index) => {
            const haveNoPaidTranche = serviceCustomer.arrayTranche.some(
              (x) => x.paid === false && !x.deleted && new Date(x.expectedPaymentDate) < new Date()
            );

            return (
              <React.Fragment key={index}>
                {haveNoPaidTranche && (
                  <table key={`table_${index}_${serviceCustomer._id}`} className="table table-striped my-3">
                    <thead>
                      <tr key={`title_${index}_${serviceCustomer._id}`}>
                        <th colSpan={4} style={{ backgroundColor: "#114d57", color: "white" }}>
                          <div className={"d-flex justify-content-between align-items-center"}>
                            <span className={"fw-normal"}>{getServiceCustomerName(customParams.services, serviceCustomer)}</span>
                            <a
                              title="Apri il servizio"
                              className={"btn"}
                              href={`/customers/${customParams.customerData._id}/services/${serviceCustomer._id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon color={"white"} icon={faExternalLinkAlt} />
                            </a>
                          </div>
                        </th>
                      </tr>
                      <tr key={`columnTitle_${index}_${serviceCustomer._id}`}>
                        <th className={"text-center"} scope="col">
                          Tranche
                        </th>
                        <th className={"text-center"} scope="col">
                          Importo
                        </th>
                        <th className={"text-center"} scope="col">
                          Data Pagamento Prevista
                        </th>
                        <th className={"text-center"} scope="col">
                          Giorni di ritardo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceCustomer.arrayTranche.map((tranch, index) => {
                        const diffDay = moment().diff(moment(tranch.expectedPaymentDate), "days");
                        return (
                          <>
                            {!tranch.paid && diffDay > 0 ? (
                              <tr key={tranch._id}>
                                <td className={"text-center fw-bold"}>#{index + 1}</td>
                                <td className={"text-center"}>{tranch.amount}</td>
                                <td className={"text-center"}>{moment(tranch.expectedPaymentDate).format("DD/MM/YYYY")}</td>
                                <td className={`text-center fw-bold ${diffDay > 30 ? "text-danger" : "text-warning"}`}>{diffDay}</td>
                              </tr>
                            ) : null}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ) : (
        <>
          <h4 className={"text-center"}>Non ci sono tranche scadute</h4>
        </>
      )}
    </div>
  );
};

export default ExpiredTranchesArrayModalContent;

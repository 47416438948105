import {useCallback, useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {useServiceCustomer} from "../../contexts/service-customer-context";
import {renderPaginator} from "../../utils/functions";
import {useEthosNotification} from "../../contexts/ethos-notification-context";
import {debounce} from "lodash";
import {useConfigurations} from "../../contexts/configurations-context";

const ListScreenings = (props) => {
    const {getServiceCustomers} = useServiceCustomer();
    const [screenings, setScreenings] = useState([]);
    const LIMIT = 12;
    const [offset, setOffset] = useState(0);
    const [pagination, setPagination] = useState(null);
    const {handleError} = useEthosNotification();
    const [search, setSearch] = useState(null);
    const searchRef = useRef();
    const [selectedStatus, setSelectedStatus] = useState(null);
    const {configurations} = useConfigurations()

    const [sortColumn, setSortColumn] = useState({column: null, direction: 0});
    const [serviceFilter, setServiceFilter] = useState()
    const [sortFilter, setSortFilter] = useState(null);
    const sortFilterRef = useRef();

    const initScreenings = useCallback(async () => {
        const {data, error} = await getServiceCustomers("Screening", LIMIT, offset, sortFilter, search, selectedStatus);
        if (error) {
            handleError(error);
            return;
        }
        setScreenings(data.data);
        renderPaginator(data.length, LIMIT, setOffset, setPagination, offset);
    }, [getServiceCustomers, handleError, offset, search, selectedStatus, sortFilter]);

    const init = useCallback(() => {
        initScreenings();
    }, [initScreenings]);

    const resetFilter = () => {
        setOffset(0);
        setSortFilter(null);
        sortFilterRef.current.value = null;
        setSearch(null);
        searchRef.current.value = null;
        setSelectedStatus(null);
    };

    const onSearchChange = debounce((value) => {
        setSearch(value);
    }, 500);

    const onChangeStatus = debounce((value) => {
        setSelectedStatus(value);
    }, 500);

    useEffect(() => {
        init();
    }, [offset, init]);

    const getStatusLabel = (item) => {
        switch (item.status) {
            case "PROPOSED":
                return "NON AVVIATO";
            case "ACTIVE":
                if (item.properties && item.properties.steps && item.properties.steps.length) {
                    var nonCompleti = item.properties.steps.filter((x) => x.stepState !== "COMPLETED");
                    return nonCompleti.length ? "ATTIVO" : "COMPLETATO";
                }
                return "ATTIVO";
            case "ARCHIVED":
                return "ARCHIVIATO";
            case "CANCELED":
                return "ANNULLATO";
            default:
                return "N.D.";
        }
    };

    const getPaymentStateLabel = (item) => {
        var pagate = item.arrayTranche.filter((x) => x.paid);

        if (pagate.length === 0) {
            return "NON PAGATO";
        }

        if (pagate.length === item.arrayTranche.length) {
            return "PAGATO";
        }

        return "PARZIALMENTE PAGATO";
    };

    const getOwnerName = (item) => {
        const customer = item.customerData.find((c) => c._id === item.customerId);
        if (!customer) return "N.D.";
        const assignedAt = customer.assignedData.find((a) => a._id === customer.assignedAt);
        if (!assignedAt) return "N.D.";
        return `${assignedAt.first_name} ${assignedAt.last_name}`;
    };

    const getCustomerFullname = (item) => {
        const customer = item.customerData.find((c) => c._id === item.customerId);
        if (!!customer) {
            return `${customer.first_name} ${customer.last_name}`;
        }
        return "N.D.";
    };

    const sortingColumn = (columnName) => {
        if (!!sortColumn) {
            if (sortColumn.column === columnName) {
                setSortColumn({
                    column: columnName,
                    direction: sortColumn.direction === 1 ? -1 : 1,
                });
            } else {
                setSortColumn({column: columnName, direction: 1});
            }
        }
    };

    const renderSortArrow = (columnName) => {
        if (sortFilter === null) {
            return <FontAwesomeIcon icon={faSort} style={{opacity: ".50"}} className="ml-2"/>;
        }
        if (!!sortColumn) {
            if (sortColumn.column === columnName) {
                return sortColumn.direction === 1 ? (
                    <FontAwesomeIcon icon={faSortUp} className="ml-2"/>
                ) : (
                    <FontAwesomeIcon icon={faSortDown} className="ml-2"/>
                );
            }
        }
    };

    useEffect(() => {
        if (!!sortColumn.column) {
            setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
        }
    }, [sortColumn]);

    useEffect(() => {
        if (configurations) {
            setServiceFilter(configurations);
        }
    }, [configurations]);

    return (
        <>
            <div className="row">
                <div className="col-12 text-center">
                    <h3>Lista dei servizi di screening</h3>
                </div>

                <div className="row align-items-center">
                    <div className="col-12 col-md-12 col-lg-6 my-3">
                        <input
                            ref={searchRef}
                            className="form-control"
                            placeholder="Cerca un cliente"
                            onChange={(e) => {
                                onSearchChange(e.target.value);
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                        <select
                            ref={sortFilterRef}
                            className="form-select d-inline w-100"
                            onChange={(e) => {
                                if (e.target.value === "null") {
                                    setSelectedStatus(null);
                                } else {
                                    onChangeStatus(e.target.value);
                                }
                                setOffset(0);
                            }}
                        >
                            <option value="null">Filtra per Stato</option>
                            {!!serviceFilter &&
                                serviceFilter.typeServiceCustomerStatus !== null && (
                                    <>
                                        {Object.entries(serviceFilter.typeServiceCustomerStatus).map(([key, obj]) => {
                                            return (
                                                <option key={key} value={obj.value}>
                                                    {obj.label}
                                                </option>
                                            )
                                        })}
                                    </>
                                )}
                        </select>
                    </div>
                    <div className="col-12 col-md-12 col-lg-2 my-3">
                        <div className="btn-group w-100">
                            <button className="btn btn-primary">Cerca</button>

                            <button
                                className="btn btn-outline-primary"
                                onClick={() => {
                                    resetFilter();
                                }}
                            >
                                Resetta Filtri
                            </button>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="col-12 mt-1">
                    {screenings && screenings.length > 0 ? (
                        <nav className="pagination-section">
                            <ul className="pagination justify-content-center">{pagination}</ul>
                        </nav>
                    ) : null}

                    <table className="table table-striped">
                        <thead className="thead-dark">
                        <tr>
                            <th></th>
                            <th
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    sortingColumn("customerId");
                                }}
                            >
                                Cliente {renderSortArrow("customerId")}
                            </th>
                            <th
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    sortingColumn("status");
                                }}
                            >
                                Stato {renderSortArrow("status")}
                            </th>
                            <th style={{cursor: "not-allowed"}}>Stato Pagamento</th>
                            <th
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    sortingColumn("createdAt");
                                }}
                            >
                                Data Creazione {renderSortArrow("createdAt")}
                            </th>
                            <th
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    sortingColumn("customerData.first_name");
                                }}
                            >
                                Operatore {renderSortArrow("customerData.first_name")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {screenings && screenings.length > 0 ? (
                            screenings.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <button className="btn btn-secondary"
                                                    disabled={!(item.status === "ACTIVE" || item.status === "ARCHIVED")}>
                                                <NavLink to={`service-screening/${item._id}`} className="text-white">
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </NavLink>
                                            </button>
                                        </td>
                                        <td className="text-uppercase">
                                            <a href={"/customers/" + item.customerId}>
                                                <span className="fw-bold">{getCustomerFullname(item)}</span>
                                            </a>
                                        </td>
                                        <td className="text-uppercase ">{getStatusLabel(item)}</td>
                                        <td className="text-uppercase ">{getPaymentStateLabel(item)}</td>
                                        <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                                        <td>{getOwnerName(item)}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <th colSpan="6" style={{textAlign: "center"}}>
                                    Non è stato trovato nessuno Screening
                                </th>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ListScreenings;
